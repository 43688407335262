<template>
  <div>
    <div class="scroller"><span></span><span></span><span></span></div>
  </div>
</template>
<script>
export default {
  name: 'scroll-indicator',
  data() {
    return {};
  },
};
</script>
<style scoped>
.scroller span {
  display: block;
  height: 15px;
  width: 15px;
  border-bottom: 2px solid #222;
  border-right: 2px solid #222;
  transform: rotate(45deg);
  margin: -5px;
  animation-name: animate;
  animation-duration: 2s;
  animation-iteration-count: 5;
  opacity: 0;
}
.scroller span:nth-child(2) {
  animation-delay: 0.2s;
}
.scroller span:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-15px, -15px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(15px, 15px);
  }
}
</style>
